import styled from 'styled-components'

const Bar = styled.hr`
  margin-bottom: 48px;
  border: none;
  border-top: 1px solid #555;
  margin: 0 0 24px;
  width: 100%;
  height: 0;
  box-sizing: content-box;
`

export default Bar
